<template>
  <div class="mainContainer">
    <Navbar navTitle=" "></Navbar>
    <main style="max-width: 600px; margin: 20px auto 0; padding-bottom: 100px;background: #fff;">
      <van-nav-bar
        title="跨链申请"
        left-text="返回"
        right-text="创建"
        @click-left="$router.go(-1)"
        @click-right="addModal = true"
      />
      <div class="balanceContainer">
        <center class="tip">
          收帐钱包地址: {{ creditAddress }}
          <center class="tip">申请前请选转帐到收帐钱包，手续费：总量 * {{ ratio }}%，最低 {{ fee }} DPR起</center>
        </center>
        <center class="tip">收帐钱包: {{ outAddress }}
          <div><a target="_blank" :href="'https://www.deeperscan.io/deeper/account/'+outAddress+'#transfers'">点击查看钱包详细,获取交易Hash</a></div>
        </center>
        
        <van-cell v-for="(item) in applicationList"  :title="item.name" :key="item._id" is-link>

          <div style="font-size: 12px" slot="default">
            <!-- <span v-if="balanceObj[item.deeperChain]">{{getDPR(balanceObj[item.deeperChain].now.balance)}} DPR</span> - -->
            <!-- <span v-if="creditObj[item.deeperChain]">{{creditObj[item.deeperChain].now.credit}} 信用值</span> <span v-else>暂无信用值</span> -->
            <div :class="[item.status === '1' ? 'green' :'gray']">
              状态：{{ item.statusTxt }} - <span style="color:#666 !important; font-weight:600">数量：{{ item.amount }} DPR</span>
            </div>
            <div style="font-size:10px !important; color:#333">
              交易Hash: 
              <div># {{ item.transhferHash }}</div>
            </div>
            <div style="font-size:10px !important; color:#333">
              官方收帐钱包: 
              <div># {{ item.creditAddress }}</div>
            </div>
            <div style="font-size:10px !important; color:#333">
              用户取现钱包:
              <div># {{ item.outAddress }}</div>
            </div>
            <div :v-show="item.remark !== '' ">
              审核结果: {{item.remark}}
            </div>
            <div style="font-size:8px !important; color:#999">
              创建时间:{{ item.updatedAt }} 
              手续费：{{ item.commission_amount }}  DPR
              总费：{{ item.amount + item.commission_amount }}  DPR
            </div>
          </div>
        </van-cell>
      </div>
    </main>
    <van-popup round style="width: 100%;max-width: 600px" v-model="addModal">
      <div style="padding: 30px;">
        <van-form @submit="addSubmit">
          <van-field
            v-model="addForm.transhferHash"
            name="交易记录"
            label="交易记录"
            placeholder="请输入交易记录Hash"
            :rules="[{ required: true, message: '请输入交易记录Hash' }]"
          />
          <van-field
            v-model="addForm.amount"
            name="数量"
            label="总量"
            placeholder="请输入发送申请数量"
            :rules="[{ required: true, message: '请输入发送申请数量 DPR' }]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">创建申请</van-button>
          </div>
        </van-form>
      </div>
      
    </van-popup>

    <van-popup round style="width: 100%;max-width: 600px" v-model="showCrossChainsAddressBoxModal">
      <div style="padding: 30px;">
        <van-form @submit="submitCrossChainsAddress">
          <van-field
            v-model="crossChains.address"
            name="转帐钱包地址"
            label="转帐钱包地址"
            placeholder="核对交易出帐钱包"
            :rules="[{ required: true, message: '请注意，这个是用户的转帐支出钱包地址，请谨慎填写' }]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">确认，这是我的申请跨链支付钱包地址</van-button>
          </div>
        </van-form>
      </div>
      
    </van-popup>

    <van-popup style="background: transparent" position="bottom" v-model="chartModal">
      <div style="max-width: 600px;width: 100%; margin: 0 auto;background: #fff">
        <van-nav-bar
          :title="chartDetail.name"
          left-text="上一个"
          right-text="下一个"
          left-arrow
          right-arrow
          @click-left="openChart(chartDetailIndex-1)"
          @click-right="openChart(chartDetailIndex+1)"
        />
        <div style="font-size: 12px; text-align: center;padding: 20px 0">{{chartDetail.deeperChain}}</div>
        <div class="chartBox" id="balanceChart"></div>
        <div class="chartBox" id="creditChart"></div>
        <div style="padding: 30px">
          <div>
            <van-button block plain @click="deleteDeeperChain(chartDetailIndex)" type="danger">停止监控</van-button>
          </div>
          
        </div>
      </div>
      
    </van-popup>
    <Footer></Footer>
  </div>
</template>

<script>
// import Vue from 'vue';
import Footer from '@/components/Footer/Footer'
// const validateDeeperChain = require('@/tools/validateDeeperChain')
// import * as echarts from 'echarts';
import moment from 'moment'

const STATUS_MAP = { '-1': '待处理' , '1': '已处理' , '2':'不通过'}

export default {
  components: {
    Footer,
  },
  data() {
    return {
      activeNames: [],
      applicationList: [],
      addModal: false,
      editModal: false,
      chartModal: false,
      showCrossChainsAddressBoxModal: false,
      outAddress:'',
      addForm: {
        deeperChain: '',
        name: ''
      },
      crossChains:{
        address: '',
      },
      creditAddress:'',
      fee:0,
      ratio:0,
      balanceObj: {},
      creditObj: {},
      chartDetail: {},
      chartDetailIndex: 0,
      balanceChart: null,
      creditChart: null,
    }
  },
  mounted() {
    this.getServiceCharge()
    this.getList();
    this.getCrossChainsAddress();
  },
  methods: {
    submitCrossChainsAddress(){
      this.$fetch({
        url: '/crossChains/address',
        method:'POST',
        headers: {
          Authorization: `${window.localStorage.getItem("token")}`,
        },
        data: {
          ...this.crossChains
        }
      }).then( res => {
        console.log(res);
        if(res.success) {
          this.$toast.success();
          this.showCrossChainsAddressBoxModal = false;
          this.getCrossChainsAddress();
        }
      })
    },

    getServiceCharge(){
      this.$fetch({
        url: '/crossChains/serviceCharge',
        method:'GET',
        headers: {
          Authorization: `${window.localStorage.getItem("token")}`,
        },
        
      }).then( res => {
        const { fee , ratio, creditAddress } = res.data
        this.fee = fee;
        this.ratio = ratio;
        this.creditAddress = creditAddress;
      })
    },
    getCrossChainsAddress(){
      this.$fetch({
        url: '/crossChains/address',
        method:'GET',
        headers: {
          Authorization: `${window.localStorage.getItem("token")}`,
        },
        
      }).then( res => {
        if(!res.data) {
          this.showCrossChainsAddressBoxModal = true;
        } else {
          this.outAddress = res.data.address;
        }
      })
    },
    getList() {
      this.$fetch({
        url: '/crossChains/application',
        headers: {
          Authorization: `${window.localStorage.getItem("token")}`,
        },
      }).then(res => {
        // console.log(res)
        // this.deeperChainList = res.data;
        this.applicationList = res.data.map(v=>({
          ...v,
          statusTxt: STATUS_MAP[v.status],
          createdAt: moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment(v.updatedAt).format('YYYY-MM-DD HH:mm:ss')
        }));
        // this.getBalance(this.deeperChainList.map(it => it.deeperChain))
        // this.getCredit(this.deeperChainList.map(it => it.deeperChain))
        // if (!this.deeperChainList.length) {
        //   this.addModal = true
        // }
      })
    },
    addSubmit() {
      this.$fetch({
        url: '/crossChains/application',
        headers: {
          Authorization: `${window.localStorage.getItem("token")}`,
        },
        method: 'POST',
        data: {
          outAddress: this.outAddress,
          ...this.addForm
        }
      }).then(res => {
        if (res.success) {
          this.$toast.success()
          this.addModal = false;
          this.addForm = {
            transhferHash: '',
            amount: '0'
          }
          this.getList()
        }
        console.log(res)
      })
    }
  }
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>
.mainContainer {
  min-height: 100%;
  background: #3F3F3F;
}
.chartBox {
  height: 200px;
}

.green {
  color:#009900
}

.gray {
  color: #ccc;
}

.tip {
  font-size: 12px;
  color: #ff0000;
  margin:10px
}

</style>
